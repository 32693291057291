import React from 'react'
import { Link } from 'react-router-dom'

export const NotFound = () => {
  return (
    <div className="not-found">
      <h1>404 Not Found</h1>
      <Link to="/">Go to Home</Link>
    </div>
  )
}
