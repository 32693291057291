import React from 'react'
import './styles.css'

export function Logo() {
  return (
    <div className="logo">
      <img
        src="https://ncmaz.chisnghiax.com/wp-content/uploads/2021/10/Frame.png"
        alt="logo"
      ></img>
    </div>
  )
}
