/* eslint-disable react/prop-types */
import React from 'react'
import './styles.css'

export const Hero = ({ posts }) => {

  console.log(posts)

  return <div className="hero">
    <div className="hero-heading">
      <h2>Trending posts 📈</h2>
      <span>Dolorem rerum error assumenda temporibus quo voluptas</span>
    </div>

    <div className="hero-content">

      <div className='hero-content-column'>
        {posts.filter((post, index) => index < 1).map((post) => (
          <div className='hero-content-column-item' key={post.id}>
            <a href="#">
              <img src={`https://${'dhkianhsfglpcjkexarl'}.nhost.run/v1/storage/files/${post.cover_id}`} alt={post.title} />
            </a>
            <div className='hero-content-column-item-content'>
              <h3>
                <a href="#">
                  {post.title}
                </a>
              </h3>
              <p>
                <span>{post.description}</span>
              </p>
              <span>{post.published_at}</span>
            </div>
          </div>
        ))}
      </div>

      <div className="hero-content-column small">
        {posts.filter((post, index) => index > 0).map((post) => (
          <div className="hero-content-column-item small" key={post.id}>
            <div className="hero-content-column-item-content small">
              <h3>
                <a href="#">{post.title}</a>
              </h3>
              <p>
                <span>{post.description}</span>
              </p>
              <span>{post.published_at}</span>
            </div>

            <a href="#">
              <img src={`https://${'dhkianhsfglpcjkexarl'}.nhost.run/v1/storage/files/${post.cover_id}`} alt={post.title} />
            </a>
          </div>
        ))}
      </div>
    </div>

    <div className="hero-background">
      <span></span>
      <span></span>
    </div>

  </div>
}
