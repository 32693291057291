import React from 'react'
import { Navigation } from './Navigation'
import { Logo } from '../Logo'
import './styles.css'

export function Header() {
  return (
    <div className="header">
      <div className="container">
        <div className="header-inner">
          <Logo />
          <Navigation />
        </div>
      </div>
    </div>
  )
}
