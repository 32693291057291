import React from 'react'
import { Hero } from '../components/Hero'

import { gql, useQuery } from '@apollo/client'

const GET_TRENDING_POSTS = gql`
query posts {
  posts {
    id
    title
    description
    content
    published
    published_at
    cover_id
  }
}
`

export const Home = () => {
  // eslint-disable-next-line no-unused-vars
  const { data, loading, error } = useQuery(GET_TRENDING_POSTS)

  if (loading) return <p>Loading...</p>
  if (error) return <p>Error!</p>

  return <div className="home container">
    {error && <p>{error.message}</p>}
    <Hero posts={data.posts} loading={loading}/>
  </div>
}
