import React from 'react'
import './styles.css'

export function Footer() {
  return <div className="footer">
    <div className="container">
      <div className="footer-content">
        <div className="footer-column">
          <a href="#">
            <img src="https://ncmaz.chisnghiax.com/wp-content/uploads/2021/10/Frame.png" alt="logo" width="80"/>
          </a>

        </div>
        <div className="footer-column">
          {/* <h5>Menu</h5> */}

          <ul>
            <li>
              <a href="/">Home</a>
            </li>
            <li>
              <a href="/latest">Latest</a>
            </li>
            <li>
              <a href="/trending">Trending</a>
            </li>
          </ul>
        </div>
        <div className="footer-column">
          <ul>
            <li>
              <a href="/contacts">Contacts</a>
            </li>
            <li>
              <a href="/about">About</a>
            </li>
          </ul>
        </div>

        <div className="footer-column end">
          <h5>About</h5>

          <p>
            There are many variations of passages of Lorem Ipsum available,
            but the majority have suffered alteration in some form
          </p>
          <p>
            Contrary to popular belief, Lorem Ipsum is not simply random text.
            It has roots in a piece of classical Latin literature from 45 BC,
            making it over 2000 years old.
          </p>
        </div>
      </div>
    </div>
  </div>
}
